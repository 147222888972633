<template>
  <div class="card p-4">
    <div class="fw-bold text-lg">Detalles de Pedido</div>
    <div class="row mt-2">
      <div class="col-md-6">


        <div v-if="data.deleted_at" class="row">
          <label class="col-sm-4 col-form-label text-danger">Eliminado</label>
          <div class="col-sm-8 m-auto">
            <p class="d-flex align-items-center justify-content-start m-auto text-danger" v-html="this.$store.state.dateFormat(data.deleted_at)" @change="this.$emit('input-change', 'created_at', $event.target.value)"></p>
          </div>
        </div>

        <div v-if="data.created_at" class="row">
          <label class="col-sm-4 col-form-label">Creación</label>
          <div class="col-sm-8 m-auto">
            <p class="d-flex align-items-center justify-content-start m-auto" v-html="this.$store.state.dateFormat(data.created_at)" @change="this.$emit('input-change', 'created_at', $event.target.value)"></p>
          </div>
        </div>


        <div class="row">
          <label for="state" class="col-sm-4 col-form-label">Estado</label>
          <div class="col-sm-8 m-auto">
            <Estado :editable="editable" :estado="data.state" @statusUpdated="this.$emit('input-change', 'state', $event)" />
          </div>
        </div>
        <div class="row">
          <label for="custom_id" class="col-sm-4 col-form-label">Referencia</label>
          <div class="col-sm-8 m-auto">
            <input
              required
              type="text"
              :class="editable ? 'form-control form-smaller' : ' form-smaller form-control-plaintext'"
              :readonly="!editable"
              :value="data.custom_id"
              @change="this.$emit('input-change', 'custom_id', $event.target.value)"
            />
          </div>
        </div>



        
        <div class="row">
          <label for="private_notes_seller" class="col-sm-12 col-form-label">Notas del pedido</label>
          <div class="col-sm-12 m-auto">
            <textarea class="form-control form-smaller mt-1" v-model="data.private_notes_seller" :readonly="!editable" id="private_notes_seller" rows="3" @change="this.$emit('input-change', 'private_notes_seller', $event.target.value)"></textarea>
          </div>
          <small class="me-0 text-xs text-right">No visible a cliente</small>
        </div>
        <div v-if="this.$store.state.auth.rol == 'worker'" class="row">
          <label for="private_notes_seller" class="col-sm-12 col-form-label">Notas del Trabajador</label>
          <div class="col-sm-12 m-auto">
            <textarea class="form-control form-smaller mt-1" v-model="data.private_notes_worker" :readonly="!editable" id="private_notes_worker" rows="3" @change="this.$emit('input-change', 'private_notes_worker', $event.target.value)"></textarea>
          </div>
          <small class="me-0 text-xs text-right">No visible al vendedor</small>
        </div>
      </div>
      <div class="col-md-6 border-left-fancy">
        <div class="row">
          <label for="custom_id" class="col-sm-8 col-form-label">PVP €</label>
          <div class="col-sm-4 m-auto">
            <input
              type="number"
              step="0.01"
              required
              :class="editable ? 'form-control form-smaller' : ' form-smaller form-control-plaintext'"
              :readonly="!editable"
              class="text-sm-left text-right"
              :value="data.real_client_price"
              @change="this.$emit('input-change', 'real_client_price', $event.target.value)"
            />
          </div>
        </div>
        <div class="row">
          <label for="custom_id" class="col-sm-8 col-form-label">Coste €</label>
          <div class="col-sm-4 m-auto">
            <input
              type="number"
              step="0.01"
              :class="editable ? 'form-control form-smaller' : ' form-smaller form-control-plaintext'"
              :readonly="!editable"
              class="text-sm-left text-right"
              :value="data.real_seller_price"
              @change="this.$emit('input-change', 'real_seller_price', $event.target.value)"
            />
          </div>
          <small>El coste es la suma de los costes de los productos</small>
        </div>
        <div class="row mt-3">
          <label for="private_notes_seller" class="col-md-12 col-form-label">Dirección de envío</label>
          <div class="col-md-12 m-auto">
            <textarea required v-model="data.client_direction_send" class="form-control form-smaller mt-1" :readonly="!editable" id="private_notes_seller" rows="3" @change="this.$emit('input-change', 'client_direction_send', $event.target.value)"></textarea>
          </div>
        </div>
        <div class="row">
          <label for="private_notes_seller" class="col-md-12 col-form-label">Dirección de Facturación</label>
          <div class="col-md-12 m-auto">
            <textarea required  v-model="data.client_direction_billing" class="form-control form-smaller mt-1" :readonly="!editable" id="private_notes_seller" rows="3" @change="this.$emit('input-change', 'client_direction_billing', $event.target.value)"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
// import Stock from "@/views/components/Stock.vue";
import Estado from "@/views/components/Estado.vue";

export default {
  components: {
    Estado,

  }, 

  props: {
    // the info that enter
    info: {
      required: true
    },
    editable: {
      default: false
    }

  },
  data() {
    return {
      data: {
        data: null
      }
    };
    
  },
  created() {
    this.data = this.info
  }


};
</script>
