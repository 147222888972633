<template>
  <div class="container mt-4">
    <div v-if="!this.loading && !error">

    <!-- P y E -->
     <div class="row ">
      <div class="d-flex justify-content-between">
        <div>
            <div class="d-flex align-items-center">
              <div class="h3 p-0 m-auto me-3">Pedido</div>  #{{ this.data.custom_id }}
            </div>
            <router-link
              class="back-button"
                :to="{
                  name: 'Orders'
                 
                }"
                > 
              <i class="bi bi-arrow-left "></i> Pedidos
            </router-link>

          </div>
        <router-link
          class="btn btn-primary ps-7 pe-7 mt-auto"
          v-if="!this.data.deleted_at"
          :to="{
            name: 'EditOrder',
            params: {
              order_id: this.data.id
            }
          }"> 
          <i class="bi bi-pencil-fill me-1"></i> Editar
        </router-link>
      </div>
     </div>

     <div class="row ">
      <!-- Detalles del pedido -->
      <div class="col-md-8 mt-4">
        <DetailsOrder  :info="data"/>
        <SelectionsOrders :info="data.selections" :editable="false"/>
        <NotificationsOrder v-if="$store.state.auth.rol == 'worker'" :data="data" class="mt-4"/>



   

      </div>
      <!-- Cliente -->
      <div class="col-md-4 ">
        <ClientCard class="mt-4"  :data="data.client" :title="'Cliente'"/>
        <ClientCard class="mt-4"  :data="data.seller" :title="'Vendedor'"/>
        <DeliveryCard class="mt-4"  :data="data" :editable="false"/>
        <HistoricCard class="mt-4" :data="data"/>
      </div>


      
     </div>
     
        

       



        
       
    </div>
    <div v-if="loading" class="d-flex justify-content-center ">
      <Cargador/>
    </div> 
    <div v-if="!loading && error" class="alert alert-danger">
      <div v-if="error">
        {{ error }}
      </div>
    </div> 
  </div>
</template>

<script>
import DetailsOrder from "@/views/orders/order/detailsTable.vue";
import ClientCard from "@/views/orders/order/ClientCard.vue";
import HistoricCard from "@/views/orders/order/HistoricCard.vue";
import SelectionsOrders from "@/views/orders/order/SelectionsOrder.vue";
import DeliveryCard from "@/views/orders/order/DeliveryCard.vue";
import NotificationsOrder from "@/views/orders/order/NotificationsOrder.vue";



import axios from "axios";

export default {
  components: {
    DetailsOrder,
    ClientCard,
    HistoricCard,
    SelectionsOrders,
    DeliveryCard,
    NotificationsOrder
  }, 
  data() {
    return {
      loading: true,
      error: false,
      data: [],
      order: {
        
      },
      
    };
    
  },
  mounted() {
    this.loadData(); 
  },
  methods: {
    loadData() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.token;
      axios
        .get(this.$store.state.baseUrl + `/api/orders/${this.$route.params.order_id}`)
        .then((response) => {
          if (response.data.rc == 1) {
            this.data = response.data.data;
          }
        })
        .catch(() => {
          this.error = true
          
        })
        .finally(() => {
          this.loading = false;
        });
    },
    
  }
};
</script>
