<template>
  <div class="container mt-4">
    <div v-if="!this.loading">
      <form  @submit.stop.prevent="this.save()">
        <!-- P y E -->
        <div class="row ">
          <div class="d-flex justify-content-between">
            <div>
                <div class="d-flex align-items-center">
                  <div v-if="this.$route.name == 'CreateClient'" class="h3 p-0 m-auto me-3">Crear Cliente</div> 
                  <div v-if="this.$route.name == 'ViewClient'" class="h3 p-0 m-auto me-3">Ver Cliente</div> 
                  <div v-if="this.$route.name == 'EditClient'" class="h3 p-0 m-auto me-3">Editar Cliente </div>  
                </div>
                <router-link
                  v-if="this.$route.name == 'EditClient' "
                  class="back-button"
                    :to="{
                      name: 'ViewClient',
                      params: {
                        client_id: this.data.id
                      }
                    }"
                    > 
                  <i class="bi bi-arrow-left "></i> Ver Cliente
                </router-link>
                <router-link
                v-if="this.$route.name == 'ViewClient' ||  this.$route.name == 'CreateClient'"

                  class="back-button"
                    :to="{
                      name: 'Clients'
                    
                    }"
                    > 
                  <i class="bi bi-arrow-left "></i> Clientes
                </router-link>

              </div>
              <!-- BOTONES -->
              <div v-if="this.$route.name !== 'ViewClient'" class="d-flex">
                <button
                v-if="this.$store.state.auth.rol === 'worker' && !this.data.deleted_at && false && this.data.id"
                @click="deleteOrder"
                type="button"
                class="btn btn-danger m-auto ps-2 pe-2 ms-2"
                >
                <i class="bi bi-trash-fill"></i>
                Eliminar
                </button>
                <button
                  type="submit"
                  v-if="!this.data.deleted_at "
                  class="btn btn-primary m-auto ps-7 pe-7 ms-2"
                  > 
                  Guardar
                </button>
              
              </div>
              <div v-else class="d-flex">
                <router-link
                  :to="{
                      name: 'EditClient',
                      params: {
                        client_id: this.data.id
                      }
                    }"
                    
                  v-if="!this.data.deleted_at "
                  class="btn btn-primary m-auto ps-7 pe-7 ms-2"
                  > 
                  Editar
                </router-link>
              </div>
          </div>
          <div v-if="error" class="alert alert-danger mt-4">
            <div>
              {{ error }}
            </div>
          </div> 
        </div>
        








        <div class="row ">
          <!-- Detalles del pedido -->
          <div class="col-md-9 mt-4" :class="{'col-md-12' : $store.state.auth.rol == 'seller'}">
            <div class="card p-4">
              <div class="fw-bold text-lg">Detalles de cliente</div>
              <div class="row mt-2">
                <div class="col-md-6" :class="{'col-md-12': editable }">

                  <div v-if="data.deleted_at" class="row">
                    <label class="col-sm-4 col-form-label text-danger">Eliminado</label>
                    <div class="col-sm-8 m-auto">
                      <p class="d-flex align-items-center justify-content-end m-auto text-danger" v-html="this.$store.state.dateFormat(data.deleted_at)"></p>
                    </div>
                  </div>

                  <div v-if="data.created_at" class="row">
                    <label class="col-sm-4 col-form-label">Creación</label>
                    <div class="col-sm-8 m-auto">
                      <p class="d-flex align-items-center justify-content-end m-auto" v-html="this.$store.state.dateFormat(data.created_at)"></p>
                    </div>
                  </div>

                  <div class="row">
                    <label for="name" class="col-sm-4 col-form-label">Nombre</label>
                    <div class="col-sm-8 m-auto">
                      <input
                        required
                        type="text"
                        :class="editable ? 'form-control form-smaller' : 'form-smaller form-control-plaintext'"
                        :readonly="!editable"
                        v-model="data.name"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <label for="sku" class="col-sm-4 col-form-label">Correo</label>
                    <div class="col-sm-8 m-auto">
                      <input
                        type="email"
                        :class="editable ? 'form-control form-smaller' : 'form-smaller form-control-plaintext'"
                        :readonly="!editable"
                        v-model="data.email"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <restore-password v-if="this.$route.name == 'ViewClient'" :userIn="data"/>
                  </div>
                  <div class="row">
                    <label for="sku" class="col-sm-4 col-form-label">Apellidos</label>
                    <div class="col-sm-8 m-auto">
                      <input
                        required
                        type="text"
                        :class="editable ? 'form-control form-smaller' : 'form-smaller form-control-plaintext'"
                        :readonly="!editable"
                        v-model="data.surname"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <label for="description" class="col-sm-4 col-form-label">Notas privadas</label>
                    <div class="col-sm-8 m-auto">
                      <textarea
                        class="form-control form-smaller mt-1"
                        v-model="data.private_notes_for_sellers"
                        :readonly="!editable"
                        rows="3"
                      ></textarea>
                      <small>No visible a cliente</small>
                    </div>
                  </div>

                  
                  
                  

                  
                </div>
                <div class="col-md-6" :class="{'col-md-12': editable }">
                  <div class="row">
                    <label for="sku" class="col-sm-4 col-form-label">Teléfono de contacto</label>
                    <div class="col-sm-8 m-auto">
                      <input
                        required
                        type="text"
                        :class="editable ? 'form-control form-smaller' : 'form-smaller form-control-plaintext'"
                        :readonly="!editable"
                        v-model="data.telephone"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <label for="description" class="col-sm-4 col-form-label">Dirección Facturación</label>
                    <div class="col-sm-8 m-auto">
                      <textarea
                        class="form-control form-smaller mt-1"
                        v-model="data.invoice_direction"
                        :readonly="!editable"
                        rows="3"
                      ></textarea>
                    </div>
                  </div>
                  <div class="row">
                    <label for="description" class="col-sm-4 col-form-label">Dirección de envío</label>
                    <div class="col-sm-8 m-auto">
                      <textarea
                        class="form-control form-smaller mt-1"
                        v-model="data.delivery_direction"
                        :readonly="!editable"
                        rows="3"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div v-if="data.id" class="col-md-6" :class="{'col-md-12': editable }">
                  <div class="row mt-2">
                    <label class="col-sm-6 col-form-label">Imagen</label>
                    <div class="col-sm-6">
                      <img class="product-img-2 w-100" v-if="data.image_url" :src="data.image_url" alt="">
                      <img v-else class="product-img-2 w-100" :src="this.$store.state.baseUrl+'/products/default.png'" alt="" />
                      <input type="file" ref="imageInput" class="d-none" @change="handleImageChange" accept="image/*">
                      <button v-if="!loading_image" type="button" class="btn btn-link p-0 mt-1 w-100 text-right" @click="openImageSelector">Cambiar Imagen</button>
                      <Cargador v-else/>
                      <div v-if="error" class="text-danger">
                        Parece que ha habido un problema - {{ this.error }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Cliente -->
          <div v-if="this.$store.state.auth.rol == 'worker'" class="col-md-3 mt-4">
            <ClientCard   @selected="handleSelectedUser"  :filter="editable? 'sellers' : null" :data="data.seller" :title="'Vendedor'"/>
          </div>
          


          
        </div>

        
        
            

       



    </form>
       
    <div v-if="$route.name== 'ViewClient'" class="row ">
      <div class="col-md-12 mt-4">
        <div class="card p-4">
          <div class="fw-bold text-lg">Órdenes en curso</div>
          <OrdersTable  :stateFilter="'in_curse'" :card="false" :client_id="data.id" :lessData="true" :showUserIno="true" :canCreate="false" :showFilter="false"/>
        </div>
      </div>
    </div>
  </div>

    <div v-if="loading" class="d-flex justify-content-center ">
      <Cargador/>
    </div> 
    
  </div>
</template>

<script>
// import Stocks from "@/views/products/Stocks.vue";

import ClientCard from "@/views/orders/order/ClientCard.vue";
// import HistoricCard from "@/views/orders/order/HistoricCard.vue";
import OrdersTable from "@/views/orders/table.vue";
import restorePassword from "@/views/profile/forget_password";


import axios from "axios";

export default {
  components: {
    ClientCard,
    restorePassword,
    OrdersTable
    // HistoricCard,
    // Stocks
  }, 
  data() {
    return {
      loading: true,
      error: false,
      new: true,
      editable: false,
      data: {
        type: Object
      },
      loading_image: false
      
      
    };
    
  },
  beforeRouteLeave (to, from, next) {
    if(this.isDataChanged() && (this.$route.name == 'EditClient' || this.$route.name == 'CreateClient')) {
      if(confirm("¿Estas seguro de salir? Tienes cambios sin guardar"))
        next()
    } else
    next()

  },
  mounted() {
    if(this.$route.params.client_id && this.$route.name == 'EditClient' ) {
      this.new = false
      this.editable = true
      this.loadData();
    } 
    if(this.$route.name == 'CreateClient' ) {
      this.new = true
      this.editable = true
      this.loading = false
    } 
    if(this.$route.params.client_id && this.$route.name == 'ViewClient' ) {
      this.new = false
      this.loadData();
    }


     
  },
  methods: {

    handleSelectedUser(userin) {
      this.data.user_id = userin.id
      this.data.seller = userin
    },
    handleInputChange(key, value) {
      // Handle the emitted value in the parent component
      
      // You can update the data in the parent component as needed.
      // For example, if you have an object called 'data' in the parent component:
      this.data[key] = value;
    },

    isDataChanged() {
      // Use JSON.stringify to compare the two objects as strings
      return (JSON.stringify(this.data) !== JSON.stringify(this.original) || (JSON.stringify(this.data.selections) !== JSON.stringify(this.original.selections)));
    },
    loadData() {
     
    
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.token;
      axios
        .get(this.$store.state.baseUrl + `/api/user/${this.$route.params.client_id}`)
        .then((response) => {
          if (response.data.rc == 1) {
            this.data = response.data.data;
            this.original = { ...response.data.data };
          }
        })
        .catch((error) => {
          this.error = error?? "Error"
          
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openImageSelector() {
      this.$refs.imageInput.click();
    },
    handleImageChange(event) {
      if(this.loading_image)
        return;
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.data.image_url = reader.result;
        };
        reader.readAsDataURL(file);

        const formData = new FormData();
        
        
        formData.append('image', file);

        this.loading_image = true;
        this.error = false

        // Realizar la solicitud POST con Axios
        
        
        axios
        .post(this.$store.state.baseUrl + "/api/user/"+this.data.id+"/edit",formData,{
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+this.$store.state.token
          }
        })

        .then(response => {
          if (response.data.rc !== 1) {
            this.error = response.data.data

          } 
        })
        .catch(error => {
          // Manejar errores de la solicitud si es necesario
          this.error = error
        })
        .finally(() => {
          this.loading_image = false;
        });
      }
    },
    save() {

        console.log(this.data)
        
        if(this.loading)
          return

        if(this.data.user_id == null && this.$store.state.auth.rol == 'worker') {
          alert('Debes seleccionar un Vendedor')
          return ;
        }

        this.loading = true
        this.error = false

        this.data.rol = 'client';

        var url = '/api/client/create'
        if(!this.new)
          url = "/api/user/"+this.data.id+"/edit"


        axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.token;
        axios
        
          .post(this.$store.state.baseUrl + url, this.data)
          .then((response) => {
            if (response.data.rc == 1) {
              // if is new we have to go to the new url 
                this.data = response.data.data;
                this.original = { ...response.data.data };
                this.$router.push(
                {
                  name:'ViewClient',
                  params: {
                    client_id: response.data.data.id
                  }
                })
            
            
              
            } 
            else if (response.data.rc == 14) {
              this.error = "Éste código EAN ya ha sido utilizado"
              this.loading = false;

            }
            else {
              this.error = response.data.data?? "Error"
              this.loading = false;

            }
          })
          .catch((error) => {
            this.error = error?? "Error"
            this.loading = false;

            
          })
          .finally(() => {
            // this.loading = false;
          });
      }
    
  }
};
</script>
