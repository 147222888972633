<template>
  <div v-if="!this.loading" class="py-4 container">
   
    <div>
    <h3>Hola {{ $store.state.auth.name }},</h3>

    <div class="row mt-3">
        <div v-if="this.data.pendingDeliveries !== null"
          class="col-xl-3 col-sm-6 mb-xl-0 mb-4"
        >
          <router-link
            :to="{
              name: 'Orders',
              query: {
                stateFilterInit: 'in_curse'
              }
              
            }"
          >
            <mini-statistics-card
              title="Pedidos En Curso"
              :value="this.data.pendingDeliveries"
              :percentage="{
                value: '+505%',
                color: 'text-success',
              }"
              :icon="{
                component: 'ni ni-money-coins',
                background: iconBackground,
              }"
              direction-reverse
            />
          </router-link>
        </div>
        <div v-if="$store.state.init.orders.length>0" class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <router-link
            :to="{
              name: 'Orders',
            }"
          >
            <mini-statistics-card
              title="Pedidos Totales"
              :value="$store.state.init.orders.length"
              :percentage="{
                value: '+3%',
                color: 'text-success',
              }"
              :icon="{
                component: ' ni ni-world',
                background: iconBackground,
              }"
              direction-reverse
            />
          </router-link>
        </div>

        <div v-if="$store.state.init.clients.length>0" class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <router-link
            :to="{
              name: 'Clients',
            }"
          >
            <mini-statistics-card
              title="Clientes"
              :value="$store.state.init.clients.length"
              :percentage="{
                value: '-2%',
                color: 'text-danger',
              }"
              :icon="{
                component: 'ni ni-paper-diploma',
                background: iconBackground,
              }"
              direction-reverse
            />
          </router-link>
        </div>
        <div  class="col-xl-3 col-sm-6 mb-xl-0">
          <mini-statistics-card
            title="Notificaciones "
            :value="$store.state.init.notifications.length"
            :percentage="{
              value: '+5%',
              color: 'text-success',
            }"
            :icon="{
              component: 'ni ni-cart',
              background: iconBackground,
            }"
            direction-reverse
          />
        </div>
        <div v-if="$store.state.init.sellers.length>0" class="col-xl-3 col-sm-6 mb-xl-0">
          <router-link
            :to="{
              name: 'Vendors',
            }"
          >
            <mini-statistics-card
              title="Número Vendedores "
              :value="$store.state.init.sellers.length"
              :percentage="{
                value: '+5%',
                color: 'text-success',
              }"
              :icon="{
                component: 'ni ni-user-run',
                background: iconBackground,
              }"
              direction-reverse
          /></router-link>
        </div>
      </div> 
      <div class="row">
        <div class="col-md-8 ">
         <div class="card">
          <div class="card-body">
            <h5>Pedidos en Curso</h5>
            
            <Table
              class="p-0 m-0"
              :showClientInfo="false"
              :showFilter="false"
              :canCreate="false"
              :showPrice="false"
              :canEdit="false"
              :lessData="true"
              :card="false"
              :stateFilterInit="'in_curse'"
            />
            <router-link
              class="text-decoration-underline"
              :to="{
                name: 'Orders',
                query: {
                  stateFilterInit: 'in_curse'
                }
              }"
              >Ver Todo</router-link
            >
          </div>
         </div>
        </div>
        <div class="col-md-4">
          <div class="card overflow-hidden" v-if="$store.state.auth.rol == 'worker'" >
            <div class="card-body  text-sm">
              <h5>Vendedores</h5>
              <div class="pe-2 ps-2">
                <ClientInfo
                  class="p-1"
                  v-for="vendor in $store.state.init.sellers.slice(0, 5)"
                  :key="vendor.id"
                  :data="vendor"
                />
              </div>
            </div>
          </div>
          <div v-if="!loadingClients" class="card overflow-hidden mt-2">
            <div class="card-body  text-sm">
              <h5>Clientes</h5>
              <div class="pe-2 ps-2">
                <ClientInfo
                  class="p-1"
                  v-for="client in  $store.state.init.clients.slice(0, 5)"
                  :key="client.id"
                  :data="client"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-7 mb-lg-0 mb-4">
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-lg-6">
                  <div class="d-flex flex-column h-100">
                    <p class="mb-1 pt-2 text-bold">Desarolla tu marca</p>
                    <h5 class="font-weight-bolder">Configura ti perfil</h5>
                    <p class="mb-5">
                      Añade una foto de to <strong>logo de empresa</strong>, en tu perfil
                      para generar imagen de marca a tus clientes.
                    </p>
                    <router-link
                      :to="{
                        name: 'Profile',
                      }"
                    >
                      Ir a perfil
                      <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                    </router-link>
                  </div>
                </div>
                <div class="col-lg-5 ms-auto text-center mt-5 mt-lg-0">
                  <div class="bg-color-verde-plano border-radius-lg h-100">
                    <img
                      src="@/assets/img/shapes/waves-white.svg"
                      class="position-absolute h-100 w-50 top-0 d-lg-block d-none"
                      alt="waves"
                    />
                    <div
                      class="position-relative d-flex align-items-center justify-content-center h-100"
                    >
                      <img
                        class="w-100 position-relative z-index-2 pt-4"
                        src="@/assets/img/illustrations/rocket-white.png"
                        alt="rocket"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="card h-100 p-3">
            <div
              class="overflow-hidden position-relative border-radius-lg bg-cover h-100"
              style="
                background-image: url('https://demos.creative-tim.com/soft-ui-dashboard/assets/img/ivancik.jpg');
              "
            >
              <span class="mask bg-gradient-dark"></span>
              <div class="card-body position-relative z-index-1 p-3 h-100">
                <div class="d-flex flex-column h-100">
                  <h5 class="text-white font-weight-bolder mb-4 pt-2">
                    Accede a nuestro blog
                  </h5>
                  <p class="text-white text-lg mb-5">
                    En nuestro blog encontrarás todo de noticias y temas relecionados con
                    la logística, packaging y todo lo necesário para a tu empresa.
                  </p>
               
                  <a
                    class="text-white font-weight-bold ps-1 mb-0 icon-move-left mt-auto"
                    href="https://narvik.io/noticias/"
                    target="_blank"
                  >
                    Saber Más
                    <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div v-else class="d-flex justify-content-center">
    <Cargador/>
  </div>
</template>
<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
// import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
// import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
// import TimelineList from "@/views/components/TimelineList.vue";
// import TimelineItem from "@/views/components/TimelineItem.vue";
// import ProjectsCard from "@/views/components/ProjectsCard.vue";
import US from "@/assets/img/icons/flags/US.png";
import DE from "@/assets/img/icons/flags/DE.png";
import GB from "@/assets/img/icons/flags/GB.png";
import BR from "@/assets/img/icons/flags/BR.png";
import Table from "@/views/orders/table.vue";
import ClientInfo from "@/views/components/ClientInfo.vue";
import axios from "axios";

import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
export default {
  name: "dashboard-default",
  data() {
    return {
      loading: true,
      loadingVendors: false,
      loadingClients: false,
      data: [],
      vendors: [],
      clients: [],
      iconBackground: "bg-color-verde-plano",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
    };
  },
  mounted() {
    this.loadData();
    
    
  },
  components: {
    MiniStatisticsCard,
    // ReportsBarChart,
    // GradientLineChart,
    // ProjectsCard,
    // TimelineList, 
    // TimelineItem,
    ClientInfo,
    Table,
  },
  methods: {
    loadData() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.token;
      axios
        .get(this.$store.state.baseUrl + "/api/dashboard")
        .then((response) => {
          if (response.data.rc == 1) {
            this.data = response.data.data;
          }
        })
        .catch(() => {
          console.error("ERROR");
          
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
