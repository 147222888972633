<template>
    <div v-if="stock.quantity!==0 && stock.state !== 'delivered'" class=" p-0 ms-0 textbox-fit">
        <div class="text-sm m-0 p-0 textbox-fit text-bold ">
        {{ stock.quantity }}
        <label class="m-0 p-0 text-sm fw-light textbox-fit x-numBolded">X</label>
        </div>
        <div class="text-xs m-0 p-0 textbox-fit text-wrap">
        {{ tranlsateType() }}
        </div>
        <div v-if="this.$store.state.auth.rol == 'worker' && stock.state == 'incoming'" @click="moveStock()" class="button-move-incomming cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-arrow-left-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"/>
            </svg>
        </div>
       
        <div  v-if="stock.incoming_date" class=" text-xs m-0 p-0 textbox-fit text-wrap bg-info p-1 rounded text-white text-bold" >
            <text v-if="!loading">{{stock.incoming_date}}</text>
            <div v-else class="spinner-border" rol="status"></div>
        </div>
    </div>
</template>
<script>
import axios from "axios";

export default {
    props: ['stock'],
    data(){
        return {
            loading: false
        }
    },
    methods: {
        tranlsateType() {
            var type = this.stock.state
            // if(type == 'draft')
            //     return "Stock";
            const translations = this.$store.state.translations;
            if (type in translations) {
                return translations[type].name;
            }
            return "Unidad"
        },
        moveStock() {
            if(this.loading)
                return
            if(!confirm("¿Deseas mover éste stock Entrante a Stock Disponible?"))
                return;
            this.loadin = true;
            axios.defaults.headers.common["Authorization"] =
            "Bearer " + this.$store.state.token;
            axios
                .post(this.$store.state.baseUrl + '/api/stock/incoming/move',{
                    stock_id: this.stock.id
                    
                })
                .then((response) => {
                    if (response.data.rc == 1) {
                        this.$emit('changeStock',response.data.data)
                        this.$emit('deleteStock', this.stock)

                        console.log(response)
                    } else {
                        alert('ERROR')
                    }
                
                })
                .catch(() => {
                    this.error = true;
                
                })
                .finally(() => {
                    this.loading = false
                });
        }
    }
}
</script>