<template>
  <div class="Cargador">
    <img :width="width" :src="logo" class="Loader-cargador" alt="Loading" />
    <span v-if="fontSize" :style="'font-size: '+fontSize" >Cargando</span>
  </div>
</template>

<script>

import logo from "@/assets/img/loader.gif";

export default {

  
  name: "Cargador",
 
  props: {
    width: {
      default: 100
    },
    fontSize: {
      default: '12px'
    }
  },
  data() {
    return {
      logo
    }
  }

  
};
</script>
