<template>
  <div class="container mt-4">
    <div v-if="!this.loading">
    <form  @submit.stop.prevent="this.save()">
      <!-- P y E -->
      <div class="row ">
        <div class="d-flex justify-content-between">
          <div>
            <div class="d-flex align-items-center">
              <div v-if="this.new" class="h3 p-0 m-auto me-3">Crear Pedido</div>  

              <div v-else class="h3 p-0 m-auto me-3">Editar Pedido #{{ this.data.custom_id }}</div>  
            </div>
            
            <router-link
              v-if="this.$route.name == 'EditOrder'"

              class="back-button"
                :to="{
                  name: 'ViewOrder',
                  params: {
                    order_id: this.data.id
                  }
                }"
                > 
              <i class="bi bi-arrow-left "></i> Ver Pedido
            </router-link>
            <router-link
              v-if="this.$route.name == 'CreateOrder'"

              class="back-button"
                :to="{
                  name: 'Orders',
                 
                }"
                > 
              <i class="bi bi-arrow-left "></i> Pedidos
            </router-link>
            

          </div>
          
          
          <!-- BOTONES -->
          <div  class="d-flex">
            <button
            v-if="this.$store.state.auth.rol === 'worker' && !this.data.deleted_at && this.data.id"
            @click="deleteOrder"
            type="button"
            class="btn btn-danger m-auto ps-2 pe-2 ms-2"
          >
          <i class="bi bi-trash-fill"></i>
          Eliminar
          </button>
          <button
            type="submit"
            v-if="!this.data.deleted_at "
            class="btn btn-primary m-auto ps-7 pe-7 ms-2"
            > 
            Guardar
          </button>
          
          </div>
          
        </div>
        <div class="col-md-12" >
          <div v-if="error" class="alert alert-danger mt-2">
            {{error}}
          </div> 
        </div>
      </div>

      

      <div class="row ">
        <!-- Detalles del pedido -->
        <div class="col-md-8 mt-4">
          <DetailsOrder @recibeData="updateDataFromSeller" @input-change="handleInputChange" :editable="true"  :info="data"/>
          <SelectionsOrders :editable="this.data.state == 'draft' || !this.data.id" @selecteds="addSelections" :info="data.selections" />
        </div>
        <!-- Cliente -->
        <div class="col-md-4 ">
          <ClientCard ref="detailsOrderRef" @selected="handleSelectedUser" class="mt-4" :filter="'clients'" :data="data.client" :title="'Cliente'"/>
          <ClientCard v-if="this.$store.state.auth.rol == 'worker'" @selected="handleSelectedUser" class="mt-4" :filter="'sellers'" :data="data.seller" :title="'Vendedor'"/>
          <DeliveryCard @deliveryDataAdded="handleDeliveryData" class="mt-4" :data="data" :editable="true"/>

          <HistoricCard class="mt-4" :data="data"/>
        </div>
      </div>
    </form>



    </div>
    <div v-if="loading" class="d-flex justify-content-center ">
      <Cargador/>
    </div> 
    
  </div>
</template>

<script>
import SelectionsOrders from "@/views/orders/order/SelectionsOrder.vue";
import DetailsOrder from "@/views/orders/order/detailsTable.vue";
import ClientCard from "@/views/orders/order/ClientCard.vue";
import HistoricCard from "@/views/orders/order/HistoricCard.vue";
import DeliveryCard from "@/views/orders/order/DeliveryCard.vue";



import axios from "axios";

export default {
  components: {
    DetailsOrder,
    ClientCard,
    HistoricCard,
    SelectionsOrders,
    DeliveryCard,
  }, 
  data() {
    return {
      loading: true,
      error: false,
      data: {
          "id": null,
          "client_price": null,
          "seller_price": null,
          "state": "draft",
          // "link": "",
          "client_direction_send": "",
          "client_direction_billing": "",
          "private_notes_seller": null,
          "private_notes_worker": null,
          "sended_on": null,
          "seller_id": null,
          "client_id": null,
          "created_at": "",
          "updated_at": "",
          "custom_id": "",
          "deleted_at": "",
          "real_client_price": 0,
          "real_seller_price": 0,
          'pick_up_date': null,
          'delivery_date': null,
          'delivery_number': null,
          'delivery_notes': null,
          'deliver_name': null,
          "client": {
              "id": null,
              "name": "",
              "surname": "",
              "email": "",
              "email_verified_at": null,
              "no_mail": null,
              "rol": "",
              "nif": null,
              "user_id": null,
              "telephone": "",
              "private_notes_for_workers": null,
              "invoice_direction": "",
              "delivery_direction": "",
              "image_url": "",
              "canReciveNotifications": null,
              "canReciveEmails": null,
              "notifyChangeState": null,
              "notifyAlertsStock": null,
              "color": null,
              "email_logistic": "",
              "telephone_logistic": "",
              "email_administracion": "",
              "telephone_administracion": "",
              "created_at": "",
              "updated_at": "",
              "company_name": "",
              "cif": "",
              "fiscal_direction": "",
              "iban": "",
              "private_notes_for_sellers": ""
          },
          "seller": {
              "id": null,
              "name": "",
              "surname": "",
              "email": "",
              "email_verified_at": null,
              "no_mail": null,
              "rol": "",
              "nif": null,
              "user_id": null,
              "telephone": "",
              "private_notes_for_workers": null,
              "invoice_direction": "",
              "delivery_direction": "",
              "image_url": "",
              "canReciveNotifications": null,
              "canReciveEmails": null,
              "notifyChangeState": null,
              "notifyAlertsStock": null,
              "color": "",
              "email_logistic": "",
              "telephone_logistic": "",
              "email_administracion": "",
              "telephone_administracion": "",
              "created_at": "",
              "updated_at": "",
              "company_name": "",
              "cif": "",
              "fiscal_direction": "",
              "iban": "",
              "private_notes_for_sellers": ""
          },
          "selections": [],
          "historics": []
      },
      original: {

      },
      order: {},
      new: true
      
    };
    
  },
  mounted() {
    if(this.$route.name == 'EditOrder' && this.$route.params.order_id) {
      this.new = false
      this.loadData(); 
    } else{
      this.loading = false
    }
   
  },
  beforeRouteLeave (to, from, next) {
    if(this.isDataChanged()) {
      if(confirm("¿Estas seguro de salir? Tienes cambios sin guardar"))
        next()
    } else
    next()

  },
  methods: {
    handleSelectedUser(user) {
      if(user.rol == 'client'){
        this.data.client = user
        this.data.client_id = user.id
        this.updateDataFromSeller(user);
      }
      if(user.rol == 'seller') {
        this.data.seller = user
        this.data.seller_id = user.id

      }
    },
    updateDataFromSeller(user) {
      console.log('updateDataFromSeller',this.$refs.detailsOrderRef)
      this.$emit('recibeData', 'Mensaje desde el padre');
      this.$nextTick(() => {
        // Accede al componente hijo a través de la referencia
        const detailsOrderComponent = this.$refs.detailsOrderRef;

        // Comunica los datos al componente hijo
        if (detailsOrderComponent) {
          // detailsOrderComponent.updateBillingAndSendingData('client_direction_send', user.client_direction_send);
          this.$emit('recibeData', 'Mensaje desde el padre');
          if (this.data.client_direction_send == null) {
            this.data.client_direction_send = user.client_direction_send;
          }
        }
      });
      
    },
    handleDeliveryData(dataout) {
      this.data.pick_up_date = dataout.pick_up_date
      this.data.delivery_date = dataout.delivery_date
      this.data.delivery_number = dataout.delivery_number
      this.data.delivery_notes = dataout.delivery_notes
      this.data.deliver_name = dataout.deliver_name
      console.log(dataout)


    },
    handleInputChange(key, value) {
      // Handle the emitted value in the parent component
      
      // You can update the data in the parent component as needed.
      // For example, if you have an object called 'data' in the parent component:
      this.data[key] = value;
    },
    isDataChanged() {
      // Use JSON.stringify to compare the two objects as strings
      return (JSON.stringify(this.data) !== JSON.stringify(this.original) || (JSON.stringify(this.data.selections) !== JSON.stringify(this.original.selections)));
    },
    loadData() {
      this.loading = true
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.token;
      axios
        .get(this.$store.state.baseUrl + `/api/orders/${this.$route.params.order_id}`)
        .then((response) => {
          if (response.data.rc == 1) {
            this.data = response.data.data;
            this.original = { ...response.data.data };
          }
        })
        .catch((error) => {
          this.error = error
          
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addSelections(dataIn) {
      this.data.selections = dataIn
    },
    deleteOrder() {
      if (confirm("¿Estás seguro de que deseas eliminar esta orden?")) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + this.$store.state.token;

        axios
          .post(this.$store.state.baseUrl + `/api/order/${this.data.id}/delete`)
          .then((response) => {
            if (response.data.rc == 1) {
              // Orden eliminada correctamente, redirige a la pantalla de la orden
              this.$router.push({
                name: 'ViewOrder',
                params: {
                  order_id: this.data.id
                }
              });
            } else {
              // Error al eliminar la orden, muestra un mensaje de error
              this.error = "Error al eliminar la orden.";
            }
          })
          .catch(() => {
            // Error al hacer la llamada a la API, muestra un mensaje de error
            this.error = "Error al eliminar la orden.";
          });
      }
    },
    save() {
      
      if(this.loading)
        return
      if(this.data.client_id == null) {
        alert('Debes seleccionar un cliente')
        return ;
      }
      if(this.data.seller_id == null && this.$store.state.auth.rol == 'worker') {
        alert('Debes seleccionar un Vendedor')
        return ;
      }

      this.loading = true
      this.error = false

      var url = '/api/order/create'
      if(!this.new)
        url = "/api/order/"+this.data.id+"/edit"


        
      this.data.client_price = this.data.real_client_price*100
      this.data.seller_price = this.data.real_seller_price*100


      axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.$store.state.token;
      axios
      
        .post(this.$store.state.baseUrl + url, this.data)
        .then((response) => {
          if (response.data.rc == 1) {
            // if is new we have to go to the new url 
              this.data = response.data.data;
              this.original = { ...response.data.data };
              this.$router.push(
              {
                name:'ViewOrder',
                params: {
                  order_id: response.data.data.id
                }
              })
           
           
            
          } 
          else if (response.data.rc == 14) {
            this.error = "Ésta referencia ya ha sido utilizada"
            this.loading = false;

          }
          else if (response.data.rc == 49) {
            let product = JSON.parse(response.data.data)
            this.error = "No hay suficiennte stock en el producto "+product.name+', sku: '+ product.sku
            this.loading = false;

          }
          else {
            this.error = response.data.data?? true
            this.loading = false;

          }
        })
        .catch(() => {
          this.error = true
          this.loading = false;

          
        })
        .finally(() => {
          // this.loading = false;
        });
    }
    
  }
};
</script>
