<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
        v-if="false"
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-color-verde-plano"
          :dark-mode="true"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <img :src="logo" class="img-login" alt="main_logo" />
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder ">
                    Bienvenido
                  </h3>
                  <p class="mb-0">Haz login y gestiona tus pedidos</p>
                </div>
                <div v-if="!success" class="card-body">
                  <form @submit.prevent="login" rol="form" class="text-start">
                    <label>Email</label>
                    <input
                      v-model="form.email"
                      id="email"
                      type="email"
                      placeholder="Email"
                      name="email"
                      class="form-control"
                      required
                    />
                    <label>Password</label>
                    <input
                      v-model="form.password"
                      id="password"
                      type="password"
                      placeholder="Password"
                      name="password"
                      class="form-control"

                      required
                    />
                    <!-- <soft-switch id="rememberMe" name="rememberMe" checked>
                      Remember me
                    </soft-switch> -->
                    <div class="text-center">
                      <soft-button
                        class="my-4 mb-2"
                        variant="gradient"
                        color="success"
                        full-width
                        >
                        <div v-if="!loading">
                          Sign in
                        </div>
                        <div v-else class="spinner-border" rol="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </soft-button>
                    </div>
                  </form>
                  <div v-if="credentialError" class="alert alert-warning">
                    Vaya, parece que hay algún problema con los credenciales
                  </div>
                  <div v-if="doesNotExist" class="alert alert-warning">
                    No se encuentra ningún usuario con éste correo
                  </div>
                </div>
                
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    ¿No tienes cuenta?
                    <a
                      href="https://narvik.io/contact/"
                      target="_blank"
                      class="font-weight-bold"
                      >Solicita Registrarte</a
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8 coverSignIn"
              >
                <div
                  class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6 "
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/img/curved-images/curved14.jpg') +
                      ')',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <app-footer class="mt-3"/>
</template>

<script>
import logo from "@/assets/img/logo-small.png";

import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
// import SoftInput from "@/components/SoftInput.vue";
// import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftButton from "@/components/SoftButton.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import axios  from 'axios';
import cookie from 'cookiejs';

export default {
  data() {
    return {
      logo,
      form: {
        email: null,
        password: null
      },
      loading: false,
      credentialError: false,
      doesNotExist: false,
      success: false

    }
  },
  name: "SignIn",
  components: {
    Navbar,
    AppFooter,
    // SoftInput,
    // SoftSwitch,
    SoftButton,
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    login() {
      // Api login call
      console.log('form',this.form)
      this.loading = true;
      
      this.credentialError = false;
      this.doesNotExist = false;
      // this.disapear = true

      var self = this
      axios
      .post(this.$store.state.baseUrl+'/api/login',this.form)
      .then((response)=>  {
         if(response.data.rc == 1) {
            this.success = true
            this.loginSuccess(response.data.data)
         }
         if(response.data.rc == 23) {
           self.doesNotExist = true
         }
          else {
           self.credentialError = true
         }
      })
      .catch((error) => {
        console.error(error)
        this.credentialError = true
      })
        // finally
      .finally(() =>  this.loading = false)


    },
    loginSuccess(data) {
      this.$store.state.loading = true;
      setTimeout(()=>{
       // add data user
      this.$store.state.auth = data.user;
      // add token to cookie
      cookie.set("authToken",data.token,{expires: 365});

      // push the router to home
      // this.$router.push({ name: "/" }); 
      window.location.href = '/';
      // window.reload()
      }, 500);

      

    }
  },
};
</script>

<style>

</style>
